import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import classes from './Nav.modules.css';
class Nav extends Component {
  render() {
    return (
      <nav id="nav-wrap" className="opaque opaqueless">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            {/* <li className="current"><a className="smoothscroll" href="#home">Home</a></li> */}
            <li>
                <NavLink activeClassName={classes.active} exact to="/">Home</NavLink>
            </li>            
            <li>
                <NavLink activeClassName={classes.active} exact to="/games">Games</NavLink>
            </li>
            <li>
                <NavLink activeClassName={classes.active} exact to="/adventures">Adventures</NavLink>
            </li>    
         </ul>

      </nav>
    );
  }
}

export default Nav;
