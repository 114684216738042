import React, { Component } from 'react';
import Modal from '../../Components/UI/Modal/Modal';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../Components/UI/Button/Button';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import About from '../../Components/About';
import Resume from '../../Components/Resume';
import Contact from '../../Components/Contact';
import Portfolio from '../../Components/Portfolio';

class Layout extends Component {
    constructor(props){
        super(props);
        this.state = {
          foo: 'bar',
          resumeData: {},
          wantsResume: false
        };
    
      }
    
      getResumeData(){
        fetch('/resumeData.json', {
          headers: {
          'Content-Type': 'application/json',
          }
        }).then(response => response.json())
          .then(data => {
            this.setState({
              ...this.state,
              resumeData: data
            });
        })
          .catch(error =>{
            console.log('failed to parse/retrieve your resume!')
        })
    
      }
      toggleResume =() =>{    this.setState({
          ...this.state,
          wantsResume: !this.state.wantsResume
        })
     }
      componentDidMount(){
        this.getResumeData();
        console.log('im in the layout?')
        
      }
    render() {
        let resumeBody = (
            <Aux>
              <p>For Resume,  please contact me using the contact me section.  Thank you!</p>
              <Button right btnType="Info" clicked={this.toggleResume}>OK</Button>
            </Aux>
          );        
        return (
            <Aux>
                <Header data={this.state.resumeData.main}/>
                <About clicked={this.toggleResume} address={this.state.resumeData.main && this.state.resumeData.main.address} data={this.state.resumeData.main}/>
                <Modal show={this.state.wantsResume} modalClosed={this.toggleResume} >
                    {resumeBody}
                </Modal>        
                <Resume data={this.state.resumeData.resume}/>
                <Portfolio data={this.state.resumeData.portfolio}/>
                {/* <Testimonials data={this.state.resumeData.testimonials}/> */}
                <Contact data={this.state.resumeData.main}/>
                <Footer data={this.state.resumeData.main}/>
            </Aux>
        );
    }
}

export default Layout;