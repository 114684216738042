import React from 'react'
import classes from './Modal.module.css';
import Backdrop from '../BackDrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const modal = props => {
    return (
        <Aux>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div className={classes.Modal}
                style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                {props.children}
            </div>
        </Aux>
    )
}

export default React.memo(modal, (prevProps, nextProps) => {
    return (nextProps.show === prevProps.show) && 
           (nextProps.children === prevProps.children)
})
