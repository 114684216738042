import React, { Component } from 'react';
class About extends Component {
   // state = {
   //    wantsResume: false
   // }

   render() {
   //console.log(this.props.address)
    //let address;
    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      
      var phone= this.props.data.phone;
      var email = this.props.data.email;
   }
   if(this.props.address){
      var city = this.props.address.city;
      var state = this.props.address.state;
      var zip = this.props.address.zip;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Mark Anderko Profile Pic" />
            
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>{name}</span><br />

						   <span>{city} {state}, {zip} </span>
						   <span>{phone}</span><br />
                     <span>{email}</span>
					   </p>
               </div>
               <div className="columns download">
                  <p>
                     <button onClick={this.props.clicked} className="button"><i className="fa fa-download"></i>Download Resume</button>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
