import React, { Component } from 'react';
import { checkValidity } from '../shared/utils';
import Input from '../Components/UI/Input/Input';
import Button from '../Components/UI/Button/Button';
import Modal from '../Components/UI/Modal/Modal';
import Aux from '../hoc/Auxiliary/Auxiliary';
import ReCAPTCHA from "react-google-recaptcha";

  
class Contact extends Component {
   state = {
      controls: {
        name:{
             elementType: 'input',
             elementConfig: {
                 type: 'text',
                 placeholder: "Jane Doe"
             },
             value:  '',
             validation: {
                 required: true
             }, 
             valid: false,
             touched: false
        },
        subject:{
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: "Subject:"
            },
            value:  '',
            validation: {
                required: true
            }, 
            valid: false,
            touched: false
        },
        message: {
         elementType: 'textarea',
         elementConfig: {
             type: 'text',
             placeholder: "I would like to..."
         },
         value:  '',
         validation: {
             required: true
         }, 
         valid: false,
         touched: false
        },
        email: {
         elementType: 'input',
         elementConfig: {
            type: 'email',
            placeholder: "test@test.com"
         },
         value:  '',
         validation: {
            required: true,
            isEmail: true
         }, 
         valid: false,
         touched: false                
        }
      },
      formIsValid: false,
      spinning: false,
      error: null,
      success: false,
      captcha: null
  };
  recaptchaRef = React.createRef();

   // componentWillMount(){
   // }
   resetForm(controls){
      for (let key in controls) {
         controls[key].value = '';
         controls[key].valid = false;
         controls[key].touched = false;
       }
       this.setState({
         controls: controls,
         formIsValid: false,
         spinning: false,
         error: null, 
         success: false, 
         captcha: null
      })       
   }
   dismissError(){
      
      this.setState({
         controls: {
            ...this.state.controls
         },
         formIsValid: false,
         spinning: false, 
         error: null,
         success: false, 
         captcha: null
      })
   }
   dismissSuccess(){
      let curr = {...this.state.controls};
      this.resetForm(curr);
   }   
   sendEmail(event){
      event.preventDefault();
      let formControls = {
         ...this.state.controls
      };
      this.setState({
         controls: formControls,
         formIsValid: true,
         spinning: true,
         error: null,
         success: false, 
         captcha: this.state.captcha
      });
      let payload = {
         from: formControls.name.value,
         subject: formControls.subject.value,
         message: formControls.message.value,
         email: formControls.email.value,
         recaptcha: this.state.captcha
      };

      //actual lambda function on the server
      let url = "https://zfhpdy7lak.execute-api.us-east-1.amazonaws.com/latest/sendEmail";

      //dev when local
      //let _url = "http://localhost:60702/sendEmail";
      fetch(url, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         body: JSON.stringify(payload)
      }).then(response =>{
         this.recaptchaRef.current.reset();
         
         this.setState({
            controls: formControls,
            formIsValid: true,
            spinning: false,
            error: null,
            success: true,
            captcha: null
         });
      }).catch(e => {
         console.trace(e);
         this.setState({
            controls: formControls,
            formIsValid: true,
            spinning: false,
            error: e.message,
            captcha: null
         })
      })

   }
   inputChangedHandler = (inputIdentifier, event) => {
      const updatedOrderForm = {
          ...this.state.controls
      };
      const updatedFormElement = { 
          ...updatedOrderForm[inputIdentifier]
      };
      updatedFormElement.value = event.target.value;
      updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
      updatedFormElement.touched = true;
      updatedOrderForm[inputIdentifier] = updatedFormElement;
      
      let formIsValid = true;
      for (let inputIdentifier in updatedOrderForm) {
          formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
      }
      this.setState({controls: updatedOrderForm, formIsValid: formIsValid, spinning: false, error: null, success: false});
  }
  captchaOk(value){
    let currentControls = { ...this.state.controls };
    console.log(value)
   this.setState({
      controls: currentControls,
      formIsValid: this.state.formIsValid,
      spinning: false, 
      error: null,
      success: false, 
      captcha: value
   })
  }

  clearCaptcha(){
   let currentControls = { ...this.state.controls };
   this.setState({
      controls: currentControls,
      formIsValid: this.state.formIsValid,
      spinning: false, 
      error: null,
      success: false, 
      captcha: null
   })
  }

   render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      //var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }
    const formElementsArray = [];
    for (let key in this.state.controls) {
        formElementsArray.push({
            id: key,
            config: this.state.controls[key]
        });
    }
    return (
      <section id="contact">
          <Modal show={this.state.error} modalClosed={this.dismissError.bind(this)} >
            
            <Aux>
               <p>{this.state.error}</p>
               <Button right btnType="Danger" clicked={this.dismissError.bind(this)}>OK</Button>
            </Aux>            
          </Modal>
          <Modal show={this.state.success} modalClosed={this.dismissSuccess.bind(this)} >
            <Aux>
                  <p>Message sent successfully! Will be in touch soon. </p>
                  <Button right btnType="Success" clicked={this.dismissSuccess.bind(this)}>OK</Button>
            </Aux>    
          </Modal>          
         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>

            <div className="ten columns">

                  <p className="lead">{message}</p>

            </div>

         </div>

         <div className="row">
            <div className="eight columns">

               <form name="contactForm" onSubmit={(event)=> this.sendEmail(event)}>
					<fieldset>
                  {formElementsArray.map((formElement,index) => (
                     <div key={formElement.id + "_div"}>
                        <label key={formElement.id + "_label"} htmlFor={formElement.id}> {formElement.id}
                           <span className="required"> *</span>
                        </label>
                        <Input 
                              key={formElement.id}
                              elementType={formElement.config.elementType}
                              elementConfig={formElement.config.elementConfig}
                              value={formElement.config.value}
                              invalid={!formElement.config.valid}
                              shouldValidate={formElement.config.validation}
                              touched={formElement.config.touched}
                              changed={this.inputChangedHandler.bind(this, formElement.id)} />
                     </div>
                  ))}
                  <div className="row">
                     <div className="align-center" style={{width: '300px', marginBottom: '30px'}}>
                        <ReCAPTCHA
                        ref={this.recaptchaRef}
                           theme="dark"
                           sitekey="6LeQf8AZAAAAACrUf0_1o8ybihawVpvdmjJ6KuYG"
                           onChange={this.captchaOk.bind(this)}
                           onExpired={this.clearCaptcha.bind(this)}
                        />
                     </div>
                  </div>
                  <div>
                     <button disabled={ !this.state.formIsValid || !this.state.captcha} className="submit">Submit</button>
                     { this.state.spinning ? 
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                     : null }
                  </div>
					</fieldset>
				   </form>

           </div>


            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">

					   <h4>Address and Phone</h4>
					   <p className="address">
						   {name}<br />
						   {street} <br />
						   {city}, {state} {zip}<br />
						   <span>{phone}</span>
					   </p>
				   </div>
{/* 
               <div className="widget widget_tweets">
                  <h4 className="widget-title">Latest Tweets</h4>
                  <ul id="twitter">
                     <li>
                        <span>
                        This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet.
                        Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum
                        <a href="#">http://t.co/CGIrdxIlI3</a>
                        </span>
                        <b><a href="#">2 Days Ago</a></b>
                     </li>
                     <li>
                        <span>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        <a href="#">http://t.co/CGIrdxIlI3</a>
                        </span>
                        <b><a href="#">3 Days Ago</a></b>
                     </li>
                  </ul>
		         </div> */}
            </aside>
      </div>
   </section>
    );
  }
}

export default Contact;
