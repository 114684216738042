import React, { Component } from 'react';
import Nav from '../../Components/Nav/Nav';
import classes from './Adventure.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
// import PhotoGrid from "react-photo-feed";
// import "react-photo-feed/library/style.css";
import Album from '../../Components/Album/Album';
import Collection from '../../Components/Collection/Collection';
import axios from 'axios';

class Adventure extends Component {
    // constructor(props) {
    //     super(props);

    // }
    state = {
        gallery: {},
        title: this.props.title,
        error: null
    }
    // componentWillMount() {

    // }

    componentDidMount() {
        axios.get('/gallery2')
        .then( response => {
            let collections = response.data && response.data;
            this.setState({
                ...this.state,
                gallery: collections,
                error: false
            })
            //console.log(collections);
            // this.setState({
            //     ...this.state,
            //     images: posts,
            //     error: false
            // });
        } )
        .catch(error => {
            // console.log(error);
            this.setState({
                ...this.state,
                error: true,
            });
        });
    }



    // componentWillUpdate(nextProps, nextState) {

    // }

    render() {
        function getAlbums(folder, folderName){
            const albums = [];
            for( let child in folder){
                if(typeof(folder[child])== "object" && folder[child] !== null){
                    let title = child.replace(/_/g, ' ');
                    // console.log("title", title);
                    // console.log("child", child);
                    // console.log('folderchild', folder[child])
                    let sourceDir = folderName + "/" + child;
                    let thumbPic = null;
                    if(folder[child].thumb){
                        let last = folder[child].thumb.split('/');
                        thumbPic = folderName + "/" + last[last.length -1]


                    }
                    // let thumbPic = folder[child].thumb;

                    console.log('thumbPic', thumbPic);
                    console.log('sourceDir', sourceDir);
                    albums.push(<Album key={child} thumb={thumbPic} source={sourceDir} title={title}/>)
                }
            }
            return albums;
        }
        const collGallery = [];
        if(this.state.gallery && Object.entries(this.state.gallery)){
            
            let colls = this.state.gallery;
            for( let x in colls){
                //check for hasChildren propertie to determine if it's an album
                let albums = null;
                if(colls[x].hasChildren){
                    albums = getAlbums(colls[x], x);
                    collGallery.push(<Collection key={x} title={x}>{albums}</Collection>);
                }
            }

        }
        console.log(collGallery);
        return (
            <Aux>
                <Nav />
                <div className={classes.Adventure}>
                    {/* <PhotoGrid columns={3} photos={demoPhotos} /> */}
                    {collGallery}
                    {/* <Collection title="2015">
                        <Album thumb="2015/leavenworth.jpg"  source="2015/Leavenworth" title="Leavenworth" />
                        <Album thumb="2015/southcarolina.jpg"  source="2015/South%20Carolina" title="South Carolina" />
                    </Collection>
                    <Collection title="2016">
                        <Album thumb="2016/lfp.jpg"  source="2016/Lake%20Forest%20Park" title="Lake Forest Park" />
                        <Album thumb="2016/freyja.jpg"  source="2016/New%20House%20and%20Freyja" title="Bought first house and new puppy" />
                        <Album thumb="2016/canada.jpg"  source="2016/Canada" title="Canada" />
                        <Album thumb="2016/yellow.jpg"  source="2016/YellowStone" title="YellowStone" />
                        <Album thumb="2016/alexborn.jpg"  source="2016/Alexander%20Born" title="Birth of Alexander" />
                        <Album thumb="2016/carolina.jpg"  source="2016/Carolina" title="Carolina Christmas" />
                    </Collection>                     */}
                </div>
            </Aux>
        );
    }
}


export default Adventure;