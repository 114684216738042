
import React, { useState } from 'react'
import classes from './Album.module.css';
import { useHistory } from "react-router";

const Album = props => {
    const history = useHistory();
    const [description, setShowDescription] = useState(false);
    const handleClick =  () => {
        history.push(`/gallery?description=${props.title}&source=${btoa(props.source)}`)
    }
    const showDescription = () =>{
        setShowDescription(!description)
    }
    const imgSrc = `images/adventures/${props.thumb}`;
    return (
        <div className={classes.Album} onClick={handleClick.bind(this)}>
            <img onMouseEnter={showDescription.bind(this)} onMouseLeave={showDescription.bind(this)} src={imgSrc} title={props.title} />
            <span className={ !description ? classes.hide : classes.show}>{props.title}</span>
        </div>

    )
}


export default Album
