import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';

axios.defaults._baseURL = 'http://localhost:60702';
axios.defaults.baseURL = 'https://zfhpdy7lak.execute-api.us-east-1.amazonaws.com/latest';

// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    //console.log('success!!!', response);
    // Edit request config
    return response;
}, error => {
    if(error.response && error.response.status == 403){
        window.location.pathname = '/testlogin'
    }
    return Promise.reject(error);
});

const app = (
        <BrowserRouter>
            <App/>
        </BrowserRouter>
)
ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
