import React, {useState} from 'react';
import axios from 'axios';
import classes from './Guard.module.css';
const Guard = () => {
    const [elemConfig, setConfig] = useState({
        readOnly: true
    })

    const removeElem = ()=>{
        console.log('remove')
        document.getElementById('Password').removeAttribute('readonly');
    }
    const addElem = ()=>{
        console.log('add')
        document.getElementById('Password').setAttribute('readonly', 'readonly');
    }
    const submitForm = (event) =>{
        event.preventDefault();
        let pass = event.target.children[0].value;
        let body = {password: pass};
        let url = "https://zfhpdy7lak.execute-api.us-east-1.amazonaws.com/latest/api/testlogin";
        fetch(url, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            redirect: 'follow',
            body: JSON.stringify(body)
         }).then(response =>{
            window.location.pathname = '/adventures'
            //console.log("SUCCESS!!!")
         }).catch(e => {
            console.trace(e);
            //alert('failed!!!')
         })
        // axios.post('api/testlogin' , JSON.stringify(body))
        // .then( response => {
        //     console.log(response);
        //     debugger
        //     window.location.pathname = '/adventures';
        // } )
        // .catch(error => {
        //     // console.log(error);
        //     alert('problems', error)

        // });
    }
    return (
        <div className={classes.Guard}>
            <h3>Password required</h3>
            <form onSubmit={submitForm} >
              <input type="password" id="Password" name="Password" autoComplete="new-password" readOnly onBlur={addElem} onFocus={removeElem} />

            <button type="submit">Sign In</button>
            </form>
        </div>
    )
}

export default Guard
