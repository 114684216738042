import React from 'react'
import classes from './Collection.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
const Collection = props => {
    return (
        <Aux>
            <h1>{props.title}</h1>
            <div className={classes.Collection}>
                {props.children}
            </div>
        </Aux>
    )
}

export default Collection
