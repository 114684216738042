import React from 'react'
import classes from './Loading.module.css';

const Loading = props => {
    
    return (
        <div className={classes.Loading}>
            Loading....
        </div>
    )
}


export default Loading
