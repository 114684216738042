import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Containers/Layout/Layout';
import Loading from './Components/UI/Loading/Loading';
import './App.css';
import Adventure from './Containers/Adventure/Adventure';
import Guard from './Containers/Guard/Guard';

const Game = React.lazy(()=> {
  return import('./Components/Game/Game')
});

const Gallery = React.lazy(()=> {
  return import('./Components/Gallery/Gallery')
});
class App extends Component {

  render() {
    let routes = (
      <Switch>
        <Route path="/adventures" component={Adventure} />
        <Route path="/games" render={()=> <Game />} />
        <Route path="/gallery" render={()=> <Gallery  />} />
        <Route path='/testlogin' component={Guard} />
        <Route path="/" exact component={Layout} />
        <Redirect to="/" component={Layout} />
      </Switch>
    );    

    return (
        <div className="App">
          <Suspense fallback={<Loading />}>{routes}</Suspense>
        </div>
    );
  }
}

export default App;
